import { Controller } from 'stimulus';
import { throttle } from 'lodash';
import Swiper, { A11y, Pagination } from 'swiper';

// Register Swiper modules
Swiper.use([A11y, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination'];

  connect() {
    this.throttleHandleResize = throttle(this.handleResize.bind(this), 150);
    window.addEventListener('resize', this.throttleHandleResize);
    this.initSwiper();
  }

  disconnect() {
    this.destroySwiper();

    if (this.throttleHandleResize) {
      window.removeEventListener('resize', this.throttleHandleResize);
    }
  }

  /**
   * initSwiper
   * - Initialize the Swiper instance
   * - Conditionally initialize the Swiper instance based on the window width,
   *  and the data attribute `isSlider` on the sliderTarget element
   */
  initSwiper() {

    // Ensure that we want this to be a slider
    const isSlider = this.sliderTarget.dataset?.isSlider === "true"; // Number of slides is greater than the column layout
    const windowWidth = window.innerWidth;

    // If the window width is greater than or equal to 700px and the slider is not intended to be a slider
    // - small screens should always be a slider
    if (windowWidth >= 700 && !isSlider) {
      // Destroy the Swiper instance
      this.destroySwiper();
      // Short-circuit
      return;
    }

    // Ensure Swiper is initialized only once
    if (this.swiper) return;

    // Retrieve the number of columns from the data attribute `cols` on the sliderTarget element
    const cols = parseInt(this.sliderTarget?.dataset?.cols) || 2;

    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 10,
      a11y: {
        enabled: true,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      speed: 450,
      breakpoints: {
        700: {
          slidesPerView: cols,
          spaceBetween: 42,
          centeredSlides: false,
        },
      },
    });

    // Hack-fix for Swiper pagination not updating after a swipe gesture
    this.swiper.on('slideChangeTransitionEnd', () => {
      // Swiper pagination does not hold the correct index when swiping when there are multiple Swiper instances
      // Short-circuit if pagination does not exist
      if (!this.swiper.pagination) return;
      this.swiper.pagination.render();
      this.swiper.pagination.update();
    });
  }

  /**
   * destroySwiper
   * - Destroy the Swiper instance, if it exists
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
  }

  /**
   * handleResize
   * - Handle the window resize event
   */
  handleResize() {
    // We always want to check if we can initialize the Swiper instance
    this.initSwiper();
  }
}
